/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-unresolved
import { PageLayout } from "components/PageLayout";
import { useUserAccess } from "hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Manual() {
  const navigate = useNavigate();
  const { roleType } = useUserAccess();

  useEffect(() => {
    if (roleType !== "superadmin") {
      navigate("/404");
    }
  }, []);
  return (
    <PageLayout title="Manual LIS-Sebrae">
      <div>
        <ul>
          <li>
            <a href="/manual/services">Serviços</a>
          </li>
          <li>
            <a href="manual/modules">Módulos</a>
          </li>
        </ul>
      </div>
    </PageLayout>
  );
}

export { Manual };
