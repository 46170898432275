/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import styles from "./Users.module.scss";
import React, { useEffect, useState } from "react";
import { Spin, Table, Space, Button, message, Tooltip, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { PageLayout } from "components/PageLayout";
import { deleteUser, getAllUsers } from "api/Users/users.service";
import { UserModal } from "containers/Users/UserModal/";
import moment from "moment";
import { useUserAccess } from "hooks";
import { ConfirmModal } from "components/ConfirmModal";
// eslint-disable-next-line react/function-component-definition
const Users = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserForEdit, setSelectedUserForEdit] = useState();

  const [userModalVisible, setUserModalVisible] = useState(false);

  const [searchText, setSearchText] = useState("");

  const filteredData =
    data &&
    data.filter(user => {
      const text = searchText.toLowerCase();
      return (
        user.email.toLowerCase().includes(text) ||
        user.name.toLowerCase().includes(text) ||
        user.last_name.toLowerCase().includes(text) ||
        user.state.toLowerCase().includes(text) ||
        user.role_type.toLowerCase().includes(text) ||
        user.expiration_date.toLowerCase().includes(text) ||
        user.status.toLowerCase().includes(text)
      );
    });

  const userDelete = id => {
    setLoading(true);
    deleteUser(id)
      .then(() => {
        const newData = [...data];
        const indx = newData.map(d => d.id).indexOf(id);
        if (indx) {
          newData.splice(indx, 1);
          setData(newData);
        }
        message.success("Usuário excluido com sucesso!");
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        refresh();
        setLoading(false);
      });
  };

  const loggedUserEmail = useUserAccess().user.sub;
  const userRole = useUserAccess().user.role;
  const sameUser = record => {
    let boolean;
    if (data !== undefined) {
      if (record.email === loggedUserEmail) {
        boolean = true;
      }
    } else {
      boolean = false;
    }
    if (userRole === "admin_estadual" && record.user_type === "admin_nacional") {
      boolean = true;
    }
    return boolean;
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 4,
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5,
      },
    },
    {
      title: "Sobrenome",
      dataIndex: "last_name",
      key: "last_name",
      sorter: {
        compare: (a, b) => a.last_name.localeCompare(b.last_name),
        multiple: 3,
      },
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
      sorter: {
        compare: (a, b) => a.state.localeCompare(b.state),
        multiple: 2,
      },
    },
    {
      title: "Role do usuário",
      dataIndex: "role",
      key: "role",
      filters: [
        {
          text: "Usuário",
          value: "usuario",
        },
        {
          text: "Admin",
          value: "admin",
        },
        {
          text: "Gestor",
          value: "gestor",
        },
      ],
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: "Tipo de usuário",
      dataIndex: "role_type",
      key: "role_type",
      filters: [
        {
          text: "Usuário comum",
          value: "usuario",
        },
        {
          text: "Usuário estadual",
          value: "admin_estadual",
        },
        {
          text: "Usuário nacional",
          value: "admin_nacional",
        },
      ],
      onFilter: (value, record) => record.role_type.indexOf(value) === 0,
    },
    {
      title: "Data de expiração",
      dataIndex: "expiration_date",
      key: "expiration_date",
      sorter: {
        compare: (a, b) => {
          const [aDay, aMonth, aYear] = a.expiration_date.split("/");
          const [bDay, bMonth, bYear] = b.expiration_date.split("/");
          return moment(new Date(bYear, bMonth, bDay)).diff(new Date(aYear, aMonth, aDay));
        },
        multiple: 1,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Ativo",
          value: true,
        },
        {
          text: "Inativo",
          value: false,
        },
      ],
      onFilter: (value, record) => record.enabled === value,
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        // eslint-disable-next-line no-sequences
        <Space size="middle">
          <Tooltip placement="topLeft" title="excluir">
            <Button
              shape="circle"
              type="ghost"
              icon={
                <DeleteOutlined
                  style={{
                    color: sameUser(record) ? "#ccc" : "#ff0000",
                  }}
                />
              }
              onClick={() => {
                setSelectedUser(record);
                handleOpenModalConfirm();
              }}
            />
          </Tooltip>
          <Tooltip placement="topLeft" title="editar">
            <Button
              shape="circle"
              type="ghost"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedUserForEdit(record);
                setUserModalVisible(true);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getAllUsers()
      .then(res => {
        mountTable(res);
        setLoading(false);
      })
      .catch(err => {
        message.error(err.message);
      });
  }, []);

  const refresh = () => {
    getAllUsers()
      .then(res => {
        mountTable(res);
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(setLoading(false));
  };

  const mountTable = users => {
    users = users.map(user => {
      user["key"] = user.id;
      user["expiration_date"] = moment(user.expiration_date).format("DD/MM/YYYY");
      user["status"] = user.enabled ? "Ativo" : "Inativo";
      user["role_type"] = user.role_type.replace("admin_", "");
      return user;
    });
    setData(users);
  };

  const handleCloseConfirmModal = () => setOpenConfirmModal(prevState => !prevState);

  const handleOpenModalConfirm = () => setOpenConfirmModal(true);

  // eslint-disable-next-line no-shadow
  const handleUserDelete = selectedUser => {
    userDelete(selectedUser.id);
    handleCloseConfirmModal();
  };

  return (
    <PageLayout title="Usuários" subTitle="Gerencie os usuários que terão acesso a LIS.">
      <UserModal
        mountTable={mountTable}
        pageIsLoading={loading}
        selectedUser={selectedUserForEdit}
        setSelectedUser={setSelectedUserForEdit}
        visible={userModalVisible}
        setVisible={setUserModalVisible}
      />
      <Space direction="vertical" className={styles.users_listing}>
        <Spin spinning={loading} size="large" />
        <Input
          placeholder="Buscar..."
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          style={{ marginBottom: 16, width: 300 }}
        />
        {columns && data && (
          <Table
            className={styles.table}
            dataSource={filteredData}
            columns={columns}
            pagination={{ showSizeChanger: data.length > 10 }}
          />
        )}
      </Space>
      <ConfirmModal
        title={
          selectedUser != null
            ? `Você está prestes a remover o usuário "${selectedUser.name} ${selectedUser.last_name}".`
            : ""
        }
        message="A ação não poderá ser desfeita. Você tem certeza?"
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        isLoading={loading}
        type="delete"
        onConfirm={() => handleUserDelete(selectedUser)}
      />
    </PageLayout>
  );
};

export { Users };
