/* eslint-disable import/no-unresolved */
import { CaretDownOutlined, ImportOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Menu, Typography } from "antd";
import { authService } from "api";
import { useUserData } from "hooks";
import styles from "./UserProfile.module.scss";

const menu = (
  <Menu
    items={[
      {
        label: <a href="/about">Sobre a LIS</a>,
        key: "0",
        icon: <InfoCircleOutlined />,
      },
      {
        label: <Typography>Sair do sistema</Typography>,
        key: "1",
        icon: <ImportOutlined />,
        onClick: () => authService.logout(),
      },
    ]}
  />
);

// eslint-disable-next-line react/function-component-definition
const UserProfile = () => {
  const userData = useUserData("userData");
  const user = JSON.parse(userData);

  return (
    <div className={styles.container_avatar}>
      <Avatar className={styles.avatar_background} size="medium">
        {user.name.split("")[0] ?? "U"}
      </Avatar>
      <div className={styles.mr_18}>
        <Typography className={styles.tx_user_name}>{user.name ?? "Usuário"}</Typography>
        <Typography className={styles.tx_user_role}>{user.role_type ?? "admin"}</Typography>
      </div>
      <div>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="text" icon={<CaretDownOutlined className={styles.caret_down_outlined} />} />
        </Dropdown>
      </div>
    </div>
  );
};

export { UserProfile };
