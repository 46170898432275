/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Modal, Button, message, Select, DatePicker, Spin, Row, Col } from "antd";
import { Form, Input } from "antd";
import { getAllUsers, createUsers, updateUsers } from "api/Users/users.service";
import { authService } from "api";

import { useUserAccess } from "hooks";
import moment from "moment";
import styles from "./UserModal.module.scss";

// eslint-disable-next-line react/function-component-definition
const UserModal = ({
  mountTable,
  pageIsLoading,
  selectedUser,
  setSelectedUser,
  visible,
  setVisible,
}) => {
  const nodeRef = React.useRef(null);
  const [isCreating, setIsCreating] = useState(false);
  const [loading, setLoading] = useState(false);

  const { roleType, user } = useUserAccess();
  const isAdminStateUser = roleType === "admin_estadual";

  const { Option } = Select;

  if (selectedUser?.role_type === "estadual" || selectedUser?.role_type === "nacional") {
    selectedUser.role_type = `admin_${selectedUser?.role_type}`;
  }

  console.log(selectedUser);

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} necessário!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const stateMapping = {
    AC: "Acre",
    AL: "Alagoas",
    AP: "Amapá",
    AM: "Amazonas",
    BA: "Bahia",
    CE: "Ceará",
    DF: "Distrito Federal",
    ES: "Espírito Santo",
    GO: "Goiás",
    MA: "Maranhão",
    MT: "Mato Grosso",
    MS: "Mato Grosso do Sul",
    MG: "Minas Gerais",
    PA: "Pará",
    PB: "Paraíba",
    PR: "Paraná",
    PE: "Pernambuco",
    PI: "Piauí",
    RJ: "Rio de Janeiro",
    RN: "Rio Grande do Norte",
    RS: "Rio Grande do Sul",
    RO: "Rondônia",
    RR: "Roraima",
    SC: "Santa Catarina",
    SP: "São Paulo",
    SE: "Sergipe",
    TO: "Tocantins",
  };

  const adminStateUserStateMapping = Object.entries(stateMapping).reduce((acc, [key, value]) => {
    if (key === user.state) {
      return { ...acc, [key]: value };
    }
    return { ...acc };
  }, {});

  const userRoleMapping = {
    usuario: "Usuario",
    admin: "Admin",
    gestor: "Gestor",
  };

  const userTypeMapping = {
    usuario: "Comum",
    admin_estadual: "Estadual",
    admin_nacional: "Nacional",
  };

  const isEditMode = Boolean(selectedUser);

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedUser) {
      form.setFieldsValue({
        ...selectedUser,
        expiration_date: moment(selectedUser.expiration_date, "DD/MM/YYYY"),
      });
    } else {
      form.resetFields();
    }
  }, [selectedUser]);

  const onFinish = data => {
    setLoading(true);
    data["expiration_date"] = data["expiration_date"].format("YYYY-MM-DD[T]HH:mm:ss");
    data["enabled"] = true;

    setIsCreating(true);

    const apiCall = isEditMode ? updateUsers : createUsers;
    const params = isEditMode ? [selectedUser.id, JSON.stringify(data)] : [JSON.stringify(data)];

    apiCall(...params)
      .then(() => {
        message.success(
          isEditMode ? "Usuário atualizado com sucesso!" : "Usuário criado com sucesso!",
        );
        setVisible(false);
        setSelectedUser(null);
        refresh();
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        setIsCreating(false);
        setLoading(false);
      });
  };

  const refresh = () => {
    getAllUsers()
      .then(res => {
        mountTable(res);
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  const handleResetPassword = () => {
    Modal.confirm({
      title: "Confirmar redefinição de senha",
      content: "Tem certeza que deseja redefinir a senha deste usuário?",
      okText: "Sim",
      cancelText: "Não",
      onOk: () => {
        authService.recoverPassword(selectedUser.email).finally(() => {
          message.info(
            "Enviamos uma mensagem para o e-mail do usuário para a recuperação de senha!",
          );
        });
      },
    });
  };

  return (
    <>
      {!pageIsLoading && (
        <div className={styles.container_align_right}>
          <Button
            type="primary"
            onClick={() => {
              setSelectedUser(null);
              setVisible(true);
            }}
          >
            Adicionar usuário
          </Button>
        </div>
      )}
      <Modal
        transitionName=""
        maskTransitionName=""
        title={isEditMode ? "Editar usuário" : "Adicionar novo usuário"}
        centered
        nodeRef={nodeRef}
        visible={visible}
        onCancel={() => {
          setSelectedUser(null);
          setVisible(false);
        }}
        footer={[
          <Button
            disabled={isCreating || loading}
            type="primary"
            form="user-creation-form"
            key="submit"
            htmlType="submit"
          >
            <Spin spinning={loading} size="small" />
            {isEditMode ? "Salvar alterações" : "Adicionar"}
          </Button>,
          <Button
            disabled={isCreating}
            key="back"
            onClick={() => {
              setSelectedUser(null);
              setVisible(false);
            }}
          >
            Cancelar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="user-creation-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          layout="horizontal"
        >
          {/* Name Field */}
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Nome"
                type="text"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                rules={[
                  {
                    required: true,
                    message: "Insira seu Nome",
                  },
                ]}
              >
                <Input disabled={isCreating} placeholder="Nome" id="name" name="name" type="text" />
              </Form.Item>
            </Col>
          </Row>

          {/* Last Name Field */}
          <Row>
            <Col span={24}>
              <Form.Item
                name="last_name"
                label="Sobrenome"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                rules={[{ required: true, message: "Insira seu sobrenome" }]}
              >
                <Input
                  disabled={isCreating}
                  placeholder="Sobrenome"
                  id="last_name"
                  name="last_name"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Email Field */}
          <Row>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Insira um endereço de email válido",
                  },
                ]}
              >
                <Input
                  disabled={isCreating}
                  placeholder="Email"
                  id="email"
                  name="email"
                  type="email"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* State Field */}
          <Row>
            <Col span={24}>
              <Form.Item
                name="state"
                label="Estado"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                rules={[{ required: true }]}
              >
                <Select
                  disabled={isCreating}
                  placeholder="Selecione o estado"
                  id="state"
                  name="state"
                  type="select"
                >
                  {Object.keys(isAdminStateUser ? adminStateUserStateMapping : stateMapping).map(
                    key => (
                      <Option key={key} value={key}>
                        {isAdminStateUser ? adminStateUserStateMapping[key] : stateMapping[key]}
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* User Role Field */}
          <Row>
            <Col span={24}>
              <Form.Item
                name="role"
                label="Função do Usuário"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                rules={[{ required: true, message: "Selecione a função do usuário" }]}
              >
                <Select
                  placeholder="Selecione o papel do usuário"
                  id="role"
                  name="role"
                  disabled={isCreating || user?.sub === selectedUser?.email}
                >
                  {Object.keys(userRoleMapping).map(key => (
                    <Option key={key} value={key}>
                      {userRoleMapping[key]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* User Type Field (Dynamic) */}
          <Row>
            <Col span={24}>
              <Form.Item dependencies={["role"]} noStyle>
                {({ getFieldValue }) => {
                  const role = getFieldValue("role");

                  let filteredUserTypes = [];
                  if (role === "admin" || role === "gestor") {
                    filteredUserTypes = ["admin_estadual", "admin_nacional"];
                  } else if (role === "usuario") {
                    filteredUserTypes = ["usuario"];
                  }

                  return (
                    <Form.Item
                      name="role_type"
                      label="Tipo de Usuário"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className={styles.custom_form_padding}
                      rules={[{ required: true, message: "Selecione o tipo de usuário" }]}
                    >
                      <Select
                        placeholder="Selecione o tipo de usuário"
                        id="role_type"
                        name="role_type"
                        disabled={isCreating || !role || user?.sub === selectedUser?.email}
                      >
                        {filteredUserTypes.map(type => (
                          <Option key={type} value={type}>
                            {userTypeMapping[type]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>

          {/* Expiration Date Field */}
          <Row>
            <Col span={24}>
              <Form.Item
                name="expiration_date"
                label="Data de expiração"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                tooltip="Selecione a data de expiração do usuário"
                rules={[{ required: true }]}
              >
                <DatePicker
                  className={styles.data_placeholder_input}
                  disabled={isCreating}
                  placeholder="DD/MM/YYYY"
                  id="expiration_date"
                  name="expiration_date"
                  type="date"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {isEditMode && (
          <div style={{ textAlign: "left", marginTop: "16px" }}>
            <Button type="link" onClick={handleResetPassword}>
              Redefinir senha do usuário
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

export { UserModal };
