import React from "react";
import { UserOutlined, AppstoreOutlined, ApiOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useUserData } from "./useUserToken";

const useUserAccess = () => {
  const navigate = useNavigate();
  const commonMenuItems = [
    {
      label: "Serviços",
      icon: <AppstoreOutlined />,
      key: "services",
      onClick: () => navigate("/services"),
    },
    {
      label: "Tokens",
      icon: <ApiOutlined />,
      key: "tokens",
      onClick: () => navigate("/tokens"),
    },
  ];
  const adminMenuItems = [
    {
      label: "Serviços",
      icon: <AppstoreOutlined />,
      key: "services",
      onClick: () => navigate("/services"),
    },
    {
      label: "Usuários",
      icon: <UserOutlined />,
      key: "users",
      onClick: () => navigate("/users"),
    },
    {
      label: "Tokens",
      icon: <ApiOutlined />,
      key: "tokens",
      onClick: () => navigate("/tokens"),
    },
  ];

  const usuarioGestorMenuItems = [
    {
      label: "Serviços",
      icon: <AppstoreOutlined />,
      key: "services",
      onClick: () => navigate("/services"),
    },
  ];

  const access = role => {
    switch (role) {
      case "usuario":
        return usuarioGestorMenuItems;
      case "admin":
        return adminMenuItems;
      case "gestor":
        return usuarioGestorMenuItems;
      default:
        return commonMenuItems;
    }
  };

  const userData = useUserData("userData");
  const user = JSON.parse(userData);
  const roleType = user.role_type;

  const menuList = access(user.role);

  const disableTokenButton = roleType === "usuario";
  const showTokenDeleteBtn = roleType !== "usuario";

  return { user, roleType, menuList, disableTokenButton, showTokenDeleteBtn };
};

export { useUserAccess };
