/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Spin, Form, Input, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "store/actions";
import { authService } from "api/Authentication/auth.service";
import styles from "./Login.module.scss";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    authService.isAuthenticated().then(token => {
      if (token !== null) {
        navigate("/services");
      } else {
        setLoading(false);
      }
    });
  }, [navigate]);

  const onFinish = data => {
    setLoading(true);
    authService
      .login(data.username, data.password)
      .then(res => {
        if (res?.data?.role === "usuario") {
          message.error("Você não tem permissão para acessar o sistema.");
          setLoading(false);
        } else {
          dispatch(login(res));
          setLoading(false);
          navigate("/services");
        }
      })
      .catch(err => {
        navigate("/login");
        setLoading(false);
        message.error(err.message);
        Sentry.captureException(err);
      });
  };

  return (
    <>
      <Spin spinning={loading} className={styles.spinner} size="large" />
      {loading === false && (
        <div className={styles.login_page}>
          <div className={styles.login_box}>
            <div className={styles.illustration_wrapper}>
              <img
                src="https://fabricacom.com.br/wp-content/uploads/2018/07/ARTE_SITE_CLIENTES_Prancheta-1-copy-11.jpg"
                alt="Login"
              />
            </div>

            <Form
              name="login-form"
              id="login-form"
              className={styles.login_form}
              onFinish={onFinish}
            >
              <p className={styles.form_title}>Bom te ver por aqui!</p>
              <p>Realize o login no Portal da LIS</p>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Informe o usuário!",
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Informe a senha!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className={styles.login_form_button}>
                  ENTRAR
                </Button>
                <Button type="link" onClick={() => navigate("/forgotpassword")}>
                  Esqueci minha senha
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}

export { LoginPage };
