import { InfoCircleOutlined, FileSyncOutlined, BellOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./PageLayout.module.scss";

export function FooterMenu() {
  const navigate = useNavigate();

  const submenuItems = [
    {
      label: "Monitoramento",
      icon: <BellOutlined />,
      key: "monitoring",
      onClick: () => {
        window.open("https://sebrae.betteruptime.com");
      },
    },
    {
      label: "Sobre a LIS",
      icon: <InfoCircleOutlined />,
      key: "about",
      onClick: () => navigate("/about"),
    },
    {
      label: "Changelog",
      icon: <FileSyncOutlined />,
      key: "changelog",
      onClick: () => navigate("/changelog"),
    },
  ];

  const getDefaultKey = () => {
    const { pathname } = window.location;

    if (pathname.startsWith("/services")) return "services";
    if (pathname.startsWith("/tokens")) return "tokens";
    if (pathname.startsWith("/documents")) return "documents";
    if (pathname.startsWith("/about")) return "about";
    if (pathname.startsWith("/settings")) return "settings";
    if (pathname.startsWith("/changelog")) return "changelog";
    return "users";
  };

  const defaultSelectedKey = getDefaultKey();

  return (
    <Menu
      defaultSelectedKeys={[defaultSelectedKey || "about"]}
      mode="inline"
      theme="dark"
      className={styles.footer_font_size}
      items={submenuItems}
    />
  );
}
